<template>
  <div class='px-2 lg:pl-16 overflow-auto'>
    <table class='lg:my-8 text-sm'>
      <thead>
        <tr class='border-b uppercase tracking-wide bg-gray-50'>
          <table-header-filter-text 
            v-bind:filter-text.sync='filterEntity' 
            defaultLabel='회사' 
            class='pr-2 py-3 text-left border-r' />
          <table-header-filter-text 
            v-bind:filter-text.sync='filterInvestment' 
            defaultLabel='투자명칭' 
            class='px-2 py-3 text-left border-r'/>
          <table-header-filter-text 
            v-bind:filter-text.sync='filterFieldName' 
            defaultLabel='구분'
            class='px-2 py-3 text-left border-r'/>
          <table-header-filter-text 
            v-bind:filter-text.sync='filterValue'  
            defaultLabel='파일명' 
            class='px-2 py-3 text-left border-r'/>
          <th class='px-2 py-3 text-left'>생성시간</th>
          <th class='px-2 py-3 text-left'>수정시간</th>
          <th class='pl-2 py-3 text-left'>Folder</th>
        </tr>
      </thead>
      <tbody>
        <file-row v-for='file in filteredData' 
          :key='`files-liist-file-row-${file.id}`' 
          :file='file' />
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TableHeaderFilterText from '@/components/TableHeaderFilterText.vue'
import StringHelpers         from '@/utils/string-helpers'
import FileRow               from '@/views/files/FileRow.vue'

export default {
  name: 'FileLists',
  components: {
    FileRow, TableHeaderFilterText,
  },
  data () {
    return {
      files: [],
      filterEntity: '',
      filterInvestment: '',
      filterFieldName: '',
      filterValue: '',
      filterCreatedAt: '',
      filterUpdatedAt: '',
    }
  },
  computed: {
    ...mapState('customFields', [
      'customFieldValues',
    ]),
    filteredData () {
      if (this.filterEntity === '' &&
          this.filterInvestment === '' &&
          this.filterFieldName === '' &&
          this.filterValue === '' &&
          this.filterCreatedAt === '' &&
          this.filterUpdatedAt === '') {
        return this.customFieldValues
      } else {
        return this.customFieldValues.filter(field =>
                  (this.filterEntity     === '' || StringHelpers.stringIncludesInsensitive(field.entity_name, this.filterEntity)) &&
                  (this.filterInvestment === '' || StringHelpers.stringIncludesInsensitive(field.investment_name, this.filterInvestment)) &&
                  (this.filterFieldName  === '' || StringHelpers.stringIncludesInsensitive(field.custom_field_name, this.filterFieldName)) &&
                  (this.filterValue      === '' || StringHelpers.stringIncludesInsensitive(field.value, this.filterValue))
                )
      }
    }
  },
  methods: {
    ...mapActions('customFields', [
      'getCustomFieldValuesOfType',
    ]),
  },
  mounted () {
    this.getCustomFieldValuesOfType('file')
  }
}
</script>
