<template>
  <div>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>Files & Folders</h1>
      </div>
    </portal>
    <div class='tabs-container lg:pl-8 pt-4 pb-2 border-b border-gray-100 text-gray-600'>
      <label
       :class='selectedStyle("fileLists")'
       class='mr-8 px-4 pb-2 pt-3 rounded-t cursor-pointer'>
        <input value='fileLists' v-model='view' type='radio' name='view' class='hidden'/>
        Files
      </label>
      <label
       :class='selectedStyle("folder")'
       class='mr-8 px-4 pb-2 pt-3 rounded-t cursor-pointer'>
        <input value='folder' v-model='view' type='radio' name='view' class='hidden'/>
        Folders
      </label>
    </div>
    <component :is='view' />
  </div>
</template>

<script>
import FileLists from '@/views/files/FileLists.vue'
import Folder    from '@/views/folder/Folder.vue'

export default {
  name: 'Files',
  components: {
    FileLists,
    Folder,
  },
  data () {
    return {
      view: 'fileLists',
    }
  },
  methods: {
    selectedStyle (tab) {
      return (tab === this.view) ? 'text-gray-800 font-semibold border-b-2 border-gray-800' : 'border-b-2 border-transparent'
    }
  }
}
</script>
