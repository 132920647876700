<template>
  <div class='px-2 lg:px-8'>
    <div class='flex flex-row my-6'>
      <button 
        @click='cloneFolders' 
        class='border p-3 rounded-md border-gray-50 text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md flex flex-row justify-center items-center'>
        <refresh-cw-icon class='h-4' />
        조회
      </button>
    </div>
    <div>
      <folder-item :folderData='folderData' />
    </div>
  </div>
</template>

<script>

import cloneDeep   from 'lodash/cloneDeep'
import { mapActions, mapState } from 'vuex'
import { RefreshCwIcon } from 'vue-feather-icons'
import FolderItem  from '@/views/folder/FolderItem.vue'

export default {
  name: 'Folder',
  components: {
    RefreshCwIcon,
    FolderItem,
  },
  data() {
    return {
      folderData: [],
    }
  },
  computed: {
    ...mapState('folders', [
      'folders'
    ]),
  },
  methods: {
    ...mapActions('folders', [
      'getFolders'
    ]),
    cloneFolders () {
      this.getFolders().then(() => {
        this.folderData = [cloneDeep(this.folders)]
      })
    }
  },
  mounted () {
    this.cloneFolders()
  }
}
</script>
