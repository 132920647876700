<template>
  <tr class='border-t text-gray-700'>
    <td class='pr-2 py-3 border-r whitespace-no-wrap'>{{file.entity_name}}</td>
    <td class='px-2 py-3 border-r whitespace-no-wrap'>{{file.investment_name}}</td>
    <td class='px-2 py-3 border-r whitespace-no-wrap'>{{file.custom_field_name}}</td>
    <td class='px-2 py-3 border-r'>
      <a
        :href='fileURL'
        download
        class='underline hover:bg-gray-200 py-2'>
        {{ file.value }}
      </a>
    </td>
    <td class='px-2 py-3 whitespace-no-wrap'>{{convertTimeStamp(file.created_at)}}</td>
    <td class='px-2 py-3 whitespace-no-wrap'>{{convertTimeStamp(file.updated_at)}}</td>
    <td class='pl-2 py-3 whitespace-no-wrap'>{{file.folder_path}}</td>
  </tr>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'FileRow',
  props: [
    'file'
  ],
  computed: {
    fileURL () {
      return decodeURI(this.file.file_url)
    },
  },
  methods: {
    convertTimeStamp (datetime) {
      return dayjs(datetime).format('YYYY-MM-DD hh:mm:ss')
    }
  }
}
</script>
