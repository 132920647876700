<template>
  <div class ='py-2'>
    <el-tree
      :allow-drag='allowDrag'
      :allow-drop='allowDrop'
      :data='folderData'
      default-expand-all
      draggable
      highlight-current
      @node-drop='handleDrop'
      @node-click='setDefaultFileFolder'
      node-key='id'
      :props='defaultProps'
      :render-content='renderContent'>
    </el-tree>
  </div>
</template>

<script>

import { mapActions } from  'vuex'

export default {
  name: 'FolderItem',
  props: ['folderData', 'investmentId'],
  data() {
    return {
      newFolderId: 0,
      defaultProps: {
        id: 'id',
        parentFolderId: 'parent_folder_id',
        label: 'name',
        isFolder: 'is_folder',
        level: 'level',
        children: 'children',
      },
    }
  },
  methods: {
    ...mapActions('folders', [
      'createFolder',
      'deleteFolder',
      'renameFolder',
      'moveFolder',
      'moveFile',
      'defaultFolderSetting',
    ]),
    renderContent(h, { node, data }) {
      if (node.data.is_folder) {
        return (
          <span class='custom-folder-node'>
            <span>{node.label}</span>
            <span>
              <button class='text-blue-700 text-xs py-1 px-4 rounded border' on-click={ () => this.append(data)}>Append</button>
              <button class='text-blue-700 text-xs py-1 px-4 rounded border' on-click={ () => this.rename(data)}>Rename</button>
              <button class='text-red-500 text-xs py-1 px-4 rounded border' on-click={ () => this.delete(node, data)}>Delete</button>
            </span>
          </span>)
      } else {
        return (
          <span class='custom-file-node'> { node.label } </span>)
      }
    },
    append(data) {
      var folderName = prompt('폴더명을 입력하세요', '폴더명')
      if (folderName === null) {
        alert('폴더생성이 취소되었습니다.')
        return
      }
      var folderInfo = {parentFolderId: data.id, name: folderName}
      this.createFolder(folderInfo).then((result) => {
        if (result.id <= 0) {
          alert(result.message)
          return
        }
        const newChild = {
          id: result.id,
          path_name: result.path_name,
          parent_folder_id: data.id,
          name: folderName,
          is_folder: true,
          level: data.id + 1,
          children: []
        }
        if (!data.children) {
          this.$set(data, 'children', [])
        }
        data.children.push(newChild)
        this.setDefaultFileFolder(newChild)
      })
    },
    delete(node, data) {
      if (data.name === 'Root' || data.name === '임시저장') {
        alert('삭제할수 없는 폴더입니다.')
        return
      }
      if (data.children && data.children.length > 0) {
        alert('폴더안의 파일 삭제 후 식제가 가능합니다.')
        return
      }
      var deleteConfirm = confirm('폴더가 삭제됩니다.')
      if (!deleteConfirm) {
        alert('폴더 삭제가 취소되었습니다.')
        return
      }
      this.deleteFolder(data.id).then((result) => {
        if (result.id !== data.id) {
          alert(result.message)
          return
        }
        const parent = node.parent
        const children = parent.data.children || parent.data
        const index = children.findIndex(d => d.id === data.id)
        children.splice(index, 1)
        this.clearDefaultFileFolder()
      })
    },
    rename(data) {
      if (data.name === 'Root' || data.name === '임시저장') {
        alert('변경할 수 없는 폴더입니다.')
        return
      }
      var folderName = prompt('폴더명을 입력하세요', '폴더명')
      if (folderName === null) {
        alert('폴더명 변경이 취소되었습니다.')
        return
      }
      var folderInfo = {folderId: data.id, name: folderName}
      this.renameFolder(folderInfo).then((result) => {
        if (result.id !== data.id) {
          alert(result.message)
          return
        }
        data.name = folderName
        data.path_name = result.path_name
        folderInfo.pathName = data.path_name
      })
    },
    handleDrop(draggingNode, dropNode, dropType) {
      if (draggingNode.data.is_folder) {
        this.submitMoveFolder(draggingNode, dropNode, dropType)
      } else {
        this.submitMoveFile(draggingNode, dropNode, dropType)
      }
    },
    allowDrop(draggingNode, dropNode, dropType) {
      // prev는 dropNode 가 폴더일경우 parent_folder_id 를 알수 없다
      if (dropType === 'prev' && dropNode.data.is_folder) {
        return false
      }
      // inner가 아닐때 parent_folder_id 가 같으면 move 가 의미 없다
      if (dropType !== 'inner' && draggingNode.data.parent_folder_id === dropNode.data.parent_folder_id) {
        return false
      }
      // inner 일경우 drop node 는 is_folder 이어야 한다.
      if (dropType === 'inner' && !dropNode.data.is_folder) {
        return false
      }
      return true
    },
    allowDrag(draggingNode) {
      return draggingNode.data.level > 1
    },
    submitMoveFolder(draggingNode, dropNode, dropType) {
      var parentFolderId = 0
      if (dropType === 'inner') {
        parentFolderId = dropNode.data.id
      } else {
        parentFolderId = dropNode.data.parent_folder_id
      }
      var folderInfo = {folderId: draggingNode.data.id, parentFolderId: parentFolderId}
      this.moveFolder(folderInfo).then((result) => {
        if (result.id !== parentFolderId) {
          alert(result.message)
          return false
        }
        draggingNode.data.parent_folder_id = parentFolderId
        draggingNode.data.path_name = result.path_name + '/' + draggingNode.data.name
      })
    },
    submitMoveFile(draggingNode, dropNode, dropType) {
      var folderId = 0
      if (dropType === 'inner') {
        folderId = dropNode.data.id
      } else {
        folderId = dropNode.data.parent_folder_id
      }
      var folderInfo = {folderId: folderId, customFieldValueId: draggingNode.data.id}
      this.moveFile(folderInfo).then((result) => {
        if (result.id !== folderId) {
          alert(result.message)
          return false
        }
        draggingNode.data.parent_folder_id = folderId
      })
    },
    setDefaultFileFolder(data) {
      if (this.investmentId > 0 && data.is_folder) {
        var defaultFolderInfo = {
          folderId: data.id,
          pathName: data.path_name
        }
        this.defaultFolderSetting(defaultFolderInfo)
      }
    },
    clearDefaultFileFolder() {
      var defaultFolderInfo = {
        folderId: 0,
        pathName: ''
      }
      this.defaultFolderSetting(defaultFolderInfo)
    },
  }
}

</script>

<style lang='scss'>
.custom-folder-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  padding-right: 1rem;
}

.custom-file-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 1rem;
}
</style>
